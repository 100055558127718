import RegisterPage from './pages/registerFormPage';
import FollowPage from './pages/followPage';
import RequestContactPage from './pages/requestContactPage';
import RequestPage from './pages/requestPage';
import LoginPage from './pages/loginPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';

const fontFamily = { token: { fontFamily: 'Sarabun' } }

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/register"
          element={
            <ConfigProvider
              theme={fontFamily}
            >
              <RegisterPage />
            </ConfigProvider>
          }
        />
        <Route
          path="/follow-up"
          element={
            <ConfigProvider
              theme={fontFamily}
            >
              <FollowPage />
            </ConfigProvider>
          }
        />
        <Route
          path="/report-problem"
          element={
            <ConfigProvider
              theme={fontFamily}
            >
              <RequestPage />
            </ConfigProvider>
          }
        />
        <Route path="/" element={
          <ConfigProvider
            theme={fontFamily}
          >
            <RequestContactPage />
          </ConfigProvider>} />
        <Route path="/login" element={
          <ConfigProvider
            theme={fontFamily}
          >
            <LoginPage />
          </ConfigProvider>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Flex } from 'antd';

const stylesForm = {
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '30px 30px 10px 30px',
    // maxWidth: 'fit-content',
    minWidth: 'fit-content',
    width: '300px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
    margin: '20px',
}

const LoginPage = () => {
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };
    return (
        <div
            style={{
                display: 'flex',       
                justifyContent: 'center',
                alignItems: 'center',
                
            }}
        >
            <div style={stylesForm}>
                <div style={{ textAlign: 'center' }}>
                    <img src='Logo_DigitalFlow.png' alt='Logo' style={{ width: 100, height: 100, background: '#fff' }} />
                    <h2>Login to</h2>
                    <h3>Digital Flow Support</h3>
                </div>
                <Form
                    name="login"
                    initialValues={{
                        remember: true,
                    }}
                    style={{
                        maxWidth: 360,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}

                    >
                        <Input prefix={<UserOutlined />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                        <Flex justify="space-between" align="center">
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                            <a href="/login">Forgot password</a>
                        </Flex>
                    </Form.Item>

                    <Form.Item>
                        <Button block type="primary" htmlType="submit">
                            Log in
                        </Button>
                        or <a href="/register">Register now!</a>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
export default LoginPage;